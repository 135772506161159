import "./case-study-row.scss";

document.addEventListener("DOMContentLoaded", () => {
    const caseStudyRow = document.querySelector(".case-study-row");
    const caseStudyContainer = document.querySelector(".case-study-container");

    let scrollTriggerInstance;

    function isGridLayoutActive() {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        return screenWidth <= 1023 || screenHeight <= 780;
    }

    function createScrollTrigger() {
        if (isGridLayoutActive()) {
            if (scrollTriggerInstance) {
                scrollTriggerInstance.kill();
            }
            return;
        }

        const horizontalScrollLength =
            caseStudyRow.scrollWidth - caseStudyContainer.offsetWidth - 70;

        if (scrollTriggerInstance) {
            scrollTriggerInstance.kill();
        }

        scrollTriggerInstance = ScrollTrigger.create({
            trigger: ".case-study-container",
            start: "center center",
            end: () => `+=${horizontalScrollLength}`,
            pin: true,
            scrub: 1, // Slows down the scrub for smoother control
            onUpdate: (self) => {
                const scrollAmount = self.progress * horizontalScrollLength;
                gsap.to(caseStudyRow, {
                    x: -scrollAmount,
                    ease: "power2.out" // Apply easing for a smoother scroll effect
                });
            }
        });
    }

    function handleResize() {
        caseStudyRow.style.transform = ""; // Reset the transform property
        createScrollTrigger(); // Recreate the ScrollTrigger instance
    }

    function debounce(func, wait) {
        let timeout;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }

    createScrollTrigger();

    window.addEventListener("resize", debounce(handleResize, 100));

    window.addEventListener("load", () => {
        ScrollTrigger.refresh();
    });
});
